 // PRELOAD
 .smart-loader-six .spinner-cube-1, .smart-loader-six .spinner-cube-2{
      background-color: #000 !important;
 }
.smart-loader-six{
  width: 5px !important;
  height:  5px !important;
}


 // Add/remove floats
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.float-none {
  float: none;
}
.clear {
  clear: both;
}
// Text alignment
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
// Apply capital case to an element
.caps {
  text-transform: uppercase;
}

.popup_scheda ul {
    margin-left: 0;
    padding-left: 0;
    color: #000;
    list-style: none;
}
.popup_scheda ul li {
    margin-top: 5px;
    & b{
      color: #fa1c31;
    }
}


@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}


.fl-rich-text p, .fl-accordion-content {
  font-size: 18px;
}

.fl-rich-text h2{
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 0;
}


.fl-rich-text h3{
  text-align: left;
  text-transform: uppercase;
  margin-top: 0;
 span{
      font-size: 22px !important;
 }
}

h2, h3,.fl-rich-text p, .fl-accordion-button-label, .fl-accordion-content{
  font-family: $main-font;
}


p {
   & strong{
      color: $brand-primary;
    }
}

/* Custom styles */

.corner-ribbon.sticky{
  position: fixed;
}

.corner-ribbon.shadow{
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}

/* Different positions */

.ribbonHolder{
  height: 0;
}

.corner-ribbon.top-right{
    top: -45px;
    left: 37%;
    right: 0;
    position: relative;
    height: 100px;
    z-index: 999;
}
.corner-ribbon.top-right svg{
transform: rotateZ(-10deg);
}

$altezza-header:165px;
.post-type-archive-progetti .wrap.container-fluid  {
   min-height: 600px;
   min-height: calc(100vh - #{$altezza-header});
}

// WPML

.selettore-lingua {
  
  position: absolute;
  right: 20px; 
  
  & ul {
   list-style:none;
   text-align: right;
    & li a{
      background-color: #000;
       @include border-bottom-radius(5px);  
        padding: 10px;
        color: #fff;
        text-transform: uppercase;
        font-size: 10px;
        -webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
    }
    & li a:hover{
      text-decoration:none;
      background-color: #fa1c31;
      color: #fff;
      border-color: #000;
      border-style: 1px solid #000;
    }
   }
  & img{
    display: none;
  } 
}

@media (min-width: $grid-float-breakpoint-max){
  position: absolute;
  right: inherit; 
  left: 20px;
}
