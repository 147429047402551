ul#menu-menu-dx,ul#menu-menu-sx,ul#menu-menu-dx-en, ul#menu-menu-sx-en  {
    padding-bottom: 15px;
}

// NORMAL
.navbar-nav>li>a{
	padding-top: 5px  !important;
    padding-bottom: 5px !important;
}


#menu-menu-dx li , #menu-menu-dx li, #menu-menu-dx-en li , #menu-menu-dx-en li {
    background-color: #fff;
    @include border-right-radius(10px);
	@include border-bottom-radius(10px);
	-webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
 //   max-width: 150px;
}

#menu-menu-sx li, #menu-menu-sx li, #menu-menu-sx-en li, #menu-menu-sx-en li {
    background-color: #fff;
    @include border-left-radius(10px);
	@include border-top-radius(10px);
	@include border-right-radius(0px);
	@include border-bottom-radius(10px);
	-webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
	
 //   max-width: 150px;
}

// HOVER
#menu-menu-sx li:hover, #menu-menu-sx li:hover, #menu-menu-sx-en li:hover, #menu-menu-sx-en li:hover {
	 background-color: #fa1c31;
	 @include border-left-radius(10px);
	@include border-top-radius(10px);
	@include border-right-radius(0px);
	@include border-bottom-radius(10px);
	& a{
		color: #fff!important;
		padding-top: 5px;
   		padding-bottom: 5px;
	}
}
#menu-menu-dx li:hover, #menu-menu-dx li:hover, #menu-menu-dx-en li:hover, #menu-menu-dx-en li:hover {
	 background-color: #fa1c31;
	 @include border-right-radius(10px);
	@include border-bottom-radius(10px);
	& a{
		color: #fff!important;
		padding-top: 5px;
    	padding-bottom: 5px;
	}
}

//// CURRENT
#menu-menu-dx li.current_page_item , #menu-menu-dx li.current-menu-item, #menu-menu-dx-en li.current_page_item , #menu-menu-dx-en li.current-menu-item {
    background-color: #333;
    @include border-right-radius(10px);
	@include border-bottom-radius(10px);
	-webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
 //   max-width: 150px;
}

#menu-menu-sx li.current_page_item, #menu-menu-sx li.current-menu-item, #menu-menu-sx-en li.current_page_item, #menu-menu-sx-en li.current-menu-item {
    background-color: #333;
    @include border-left-radius(10px);
	@include border-top-radius(10px);
	@include border-right-radius(0px);
	@include border-bottom-radius(10px);
	-webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
	
 //   max-width: 150px;
}



li.current_page_item a , li.current-menu-item a {
    color: #fff !important;
   // padding: 5px !important;
   padding-top: 5px;
   padding-bottom: 5px;
}

@media (max-width: ($grid-float-breakpoint-max - 1px)){
	.menu-center{
		display: none;
	}

	 .navbar.navbar-default.navbar-static-top{
	 	min-height: 90px;
	 	& .navbar-header{
	 		min-height: 90px;
	 		text-align: center;
	 		
	 		& .logo-mobile{
	 			margin-left: 60px;
	 			display: inline-block;
			} 

	 	}
	 }
	 .holderLogoOverlay{
	 	//display: none;
	 }
}

@media (min-width: $grid-float-breakpoint-max){

	.menu-center{
		display: inline-block;
	    margin: 0 3vW;
	    position: relative;
	    text-align: center;
	}

	#menu-menu-sx.nav-sx,#menu-menu-sx-en.nav-sx{
		
	    text-align: left;
	}
	#menu-menu-dx.nav-dx,#menu-menu-dx-en.nav-dx{
	
	    text-align: right;
	}

	#menu-menu-sx li,#menu-menu-sx-en li{
		text-align: right;
	}
	#menu-menu-dx li,#menu-menu-dx-en li{
		text-align: left;
	}

	.menu-container{
		display: block;
	    margin: 0;
	    padding: 0;
	    text-align: center;
	}
	
	#menu-menu-sx.nav-sx,#menu-menu-dx.nav-dx,#menu-menu-sx-en.nav-sx,#menu-menu-dx-en.nav-dx{
		display: inline-flex;
	    width: calc(50% - 3vW - 158px);
	    float: none;
	    margin: 7.5px -7px;    
	}


	#menu-menu-sx.nav-sx>li,#menu-menu-dx.nav-dx>li,#menu-menu-sx-en.nav-sx>li,#menu-menu-dx-en.nav-dx>li  {
	    /* float: left; */
	    display: inline-block;
	    flex-grow: 1;
	}

}

@media (min-width: $grid-float-breakpoint-max){
   header.navbar .logo-mobile {
    display: none;
  }
}


.navbar-default{
	background-color: #fff;

}
.navbar>.container-fluid .navbar-brand, .navbar>.container .navbar-brand{
	margin-left: 0;
}

