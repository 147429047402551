footer{
	    background-color: #000;
}

footer .container{
	text-align: center;
	font-family: $main-font;
	text-transform: uppercase;
	font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #ccc;
}

footer p{
display: inline;
padding-right: 20px;
font-family: "Open Sans";
   font-size: 14px;
}


footer .fa {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
}

footer .textwidget a{
	color: #fff;
}
footer .textwidget a:hover{
	color: #fa1c31;
}

footer a {
    color: #fff;
}