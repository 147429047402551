/* Start: Recommended Isotope styles */
 
/**** Isotope Filtering ****/
 
.isotope-item {
  z-index: 2;
}
 
.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}
 
/**** Isotope CSS3 transitions ****/
 
.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.8s;
     -moz-transition-duration: 0.8s;
      -ms-transition-duration: 0.8s;
       -o-transition-duration: 0.8s;
          transition-duration: 0.8s;
}
 
.isotope {
  -webkit-transition-property: height, width;
     -moz-transition-property: height, width;
      -ms-transition-property: height, width;
       -o-transition-property: height, width;
          transition-property: height, width;
}
 
.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
     -moz-transition-property:    -moz-transform, opacity;
      -ms-transition-property:     -ms-transform, opacity;
       -o-transition-property:      -o-transform, opacity;
          transition-property:         transform, opacity;
}
 
/**** disabling Isotope CSS3 transitions ****/
 
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
     -moz-transition-duration: 0s;
      -ms-transition-duration: 0s;
       -o-transition-duration: 0s;
          transition-duration: 0s;
}
 
/* End: Recommended Isotope styles */


#isotope-list{
  margin: auto;
}

