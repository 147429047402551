
/* Button 1c */

.contBtnInvia{
	text-align: center;
}

.btnInvio:after{
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}


.btnInvio{
	border: none;
	font-family: $main-font;
	font-size: inherit;
	color: inherit;
	background: none;
	cursor: pointer;
	padding: 25px 80px;
	display: inline-block;
	margin: 15px 30px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
    color: #333 !important;
    font-size: 30px;	
	outline: none;
	position: relative;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.btnInvio:after {
	width: 0%;
	height: 100%;
	top: 0;
	left: 0;
	background: #fa1c31;
}

.btnInvio:hover,
.btnInvio:active {
	color: #0e83cd;
}

.btnInvio:hover:after,
.btnInvio:active:after {
	width: 100%;
}