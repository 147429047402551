// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #fa1c31;


// Main Font
//$main-font:         'Comfortaa';
//$secondary-font:     'Roboto';

$main-font:         "Open Sans", sans-serif;
$secondary-font:    "Nunito", sans-serif;

$grid-float-breakpoint-max: 768px;


